import axios, { AxiosResponse } from "axios";
import { CreateWorkspacePayload, InitiateTransferPayload, IsamWorkspacesResponse, LogPayload, LogTypes, RetryOrCancelTransferPayload, WorkspaceResults } from "../types/types";
import { gql, ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';

const headers = {
  "Content-Type": "application/json",
}

// Note, this is not pretty and should likely be derived from a mapped
// config.json. However, this is good enough for now.
export const getDomain = () => window.location.host.replace('transfer.', '');

// Helpers to handle with network requests
const baseURL = `https://transfer.${getDomain()}/api/v3`;
const graphQlURL = `https://isam.${getDomain()}/graphql`;

const redirectToLogin = (id: string) => {
  // NO-OP for now due to https://bluescape.atlassian.net/browse/BET-516
  // Eventually we want to go through the identity client login flows.
  console.warn(`redirectToLogin skipped`);
  // const loginUrl = `https://plugins.${getDomain()}/transfer/?transactionId=${id}`; // TODO: switch to reading from config.json: https://jira.common.bluescape.com/browse/CE-985
  // window.location.assign(loginUrl);
}

const getExecutor = (transferId: string, route: string, withCredentials = false) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: { headers: any, withCredentials?: boolean } = {
    headers,
  }

  if (withCredentials) {
    options.withCredentials = true;
  }

  return axios.get(route, options)
  .catch(err => {
    if (err.response.status === 401) {
      redirectToLogin(transferId);
      // Throwing this just so that we get better handling for response types
      const error = {
        response:
          {
            data: {
              errors: [{
                errorCode: 'UnauthorizedError'
              }]
            }
          }
        };
      throw error;
    } else {
      // Rethrow other errors, these will be handled by the app
      throw err;
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const putExecutor = (transferId: string, route: string, body: any) => {
  return getCSRFToken(transferId).then((csrfToken: string) => {
    return axios.put(route, body, {
      headers: {
        ...headers,
        "CSRF-Token": csrfToken
      },
    })
    .catch(err => {
      if (err.response.status === 401) {
        redirectToLogin(transferId);
        // Throwing this just so that we get better handling for response types
        const error = {
          response:
            {
              data: {
                errors: [{
                  errorCode: 'UnauthorizedError'
                }]
              }
            }
          };
        throw error;
      } else {
        // Rethrow other errors, these will be handled by the app
        throw err;
      }
    });
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postExecutor = (transferId: string, route: string, body: any, withCredentials = false) => {
  return getCSRFToken(transferId).then((csrfToken: string) => {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options: { headers: any, withCredentials?: boolean } = {
      headers: {
        ...headers,
        "CSRF-Token": csrfToken
      },
    }

    if (withCredentials) {
      options.withCredentials = true;
    }

    return axios.post(route, body, options)
    .catch(err => {
      if (err.response.status === 401) {
        redirectToLogin(transferId);
        // Throwing this just so that we get better handling for response types
        const error = {
          response:
            {
              data: {
                errors: [{
                  errorCode: 'UnauthorizedError'
                }]
              }
            }
          };
        throw error;
      } else {
        // Rethrow other errors, these will be handled by the app
        throw err;
      }
    });
  });
}

export const getTransferInfo = (transferId: string) => {
  return getExecutor(transferId, `${baseURL}/transfers/${transferId}`)
  .catch(err => {
    if (err.response.status === 403) {
      return claimTransfer(transferId);
    } else {
      // Rethrow other errors, these will be handled by the app
      throw err;
    }
  });
}

export const claimTransfer = (transferId: string) =>
  putExecutor(transferId, `${baseURL}/transfers/${transferId}`, undefined);

export const createNewWorkspace = (transferId: string, orgId: string, body: CreateWorkspacePayload) =>
  postExecutor(transferId, `${baseURL}/transfers/${transferId}/organizations/${orgId}/workspaces/create`, body);

export const getOrganizations = (transferId: string) =>
  getExecutor(transferId, `${baseURL}/transfers/${transferId}/organizations`);

export const getOrganizationInfo = (transferId: string, orgId: string) =>
  getExecutor(transferId, `${baseURL}/transfers/${transferId}/organizations/${orgId}`);

export const getWorkspaceInfo = (transferId: string, orgId: string, wsId: string) =>
  getExecutor(transferId, `${baseURL}/transfers/${transferId}/organizations/${orgId}/workspaces/${wsId}`);

export const sendLogsToBackend = (transferId: string, body: LogPayload) =>
  postExecutor(transferId, `${baseURL}/transfers/${transferId}/log`, body);

export const sendToBluescape = (transferId: string, orgId: string, wsId: string, body: InitiateTransferPayload) =>
  postExecutor(transferId, `${baseURL}/transfers/${transferId}/organizations/${orgId}/workspaces/${wsId}/send`, body);

export const retrySendToBluescape = (transferId: string, orgId: string, wsId: string, body: RetryOrCancelTransferPayload) =>
  postExecutor(transferId, `${baseURL}/transfers/${transferId}/organizations/${orgId}/workspaces/${wsId}/retry`, body);

export const cancelVersionUpload = (transferId: string, orgId: string, wsId: string, body: RetryOrCancelTransferPayload) =>
  postExecutor(transferId, `${baseURL}/transfers/${transferId}/organizations/${orgId}/workspaces/${wsId}/cancel`, body);

export const getTransferItemsFailedToInitiate = (transferId: string) =>
  getExecutor(transferId, `${baseURL}/transfers/${transferId}/versions/errors`);

const getCSRFToken = (transferId: string) => {
  return getExecutor(transferId, `${baseURL}/csrf`)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .then((res: any) => {
    return res.headers['csrf-token'];
  });
}

export const getWorkspaceResults = async (transferId: string, orgId: string, cursor?: string): Promise<WorkspaceResults> => {
  const filter = `filtering: { organizationId: { eq: "${orgId}" } }`;
  const next = `cursor: "${cursor}"`;
  return await handleGetWorkspaces(transferId, cursor ? next : filter);
}

export const getSearchWorkspaceResults = async (transferId: string, orgId: string, searchParam: string): Promise<WorkspaceResults> => {
  const filter = `filtering: { and: [ { organizationId: { eq: "${orgId}" } }, { name: { contains: "${searchParam}" } } ] }`
  return await handleGetWorkspaces(transferId, filter);
}

const handleGetWorkspaces = async (transferId: string, filter: string) => {
  const results: WorkspaceResults = {
    results: [],
    nextCursor: ""
  };

  const query = `query getMyWorkspaces {
    me {
      workspaces (
        ${filter}
        ordering: { orderedField: { my: { lastAccessedAt: Desc } } }
        pagination: { pageSize: 100 }
      ) {
        results {
          id
          name
          my {
            permissions
          }
        }
        next
        prev
        totalItems
      }
    }
  }`;

  try {
    const { data }: AxiosResponse<IsamWorkspacesResponse> = await postExecutor(transferId, graphQlURL, {query}, true);
    if (data?.data?.me?.workspaces?.results) {
      const workspaces = data.data.me.workspaces;
      results.results.push(...workspaces.results.map(w => ({
        uid: w.id,
        name: w.name,
        canEdit: w.my.permissions.includes("can_edit_workspace_content"),
        hasWorkspaces: true
      })));
      results.nextCursor = workspaces.next ?? "";
    }
  } catch (err) {
    // Just logging this to console, we are returning
    // an empty array and that is handled by caller
    console.error(`Unexpected error: `, err);
    sendLogsToBackend(transferId, {logType: LogTypes.Default, logMessage: `Failed to get workspace results with error: ${err}`}).catch(error => {
      console.error(`transferId: ${transferId} - Error sending logs to backend: `, error);
    });
  }

  return results;
}

// GraphQL:
let client: ApolloClient<NormalizedCacheObject> | null = null;

export const getGraphQLClient = () => {

  if (!client) {
    const wsLink = new WebSocketLink({
      uri: `wss://elementary.${getDomain()}/graphql`,
      options: {
        reconnect: true,
        // auth is handled via the cookie
      }
    });

    client = new ApolloClient({
      link: wsLink,
      cache: new InMemoryCache()
    });
  }

  return client;
}

export const CANVAS_SUBSCRIPTION = gql`
  subscription onCanvasCreated($workspace: String!, $transferId: String!) {
    commands(workspaceId: $workspace) {
      commandType: __typename
      ... on CreateElementCommand {
        element {
          elementType: __typename
          ... on Canvas {
            id
            traits(
              context:
                {
                  transferId: "http://bluescape.dev/shotgun/v1/transferId"
                }
              content:
                {
                  transferId: $transferId
                }
            )
          }
        }
      }
    }
  }
`;

export const PROGRESS_SUBSCRIPTION = gql`
  subscription onProgress($workspace: String!) {
    commands(workspaceId: $workspace) {
      commandType: __typename
      ... on CreateElementCommand {
        element {
          id
          traits
          ... on Image {
            ingestionState
          }
          ... on Video {
            ingestionState
          }
        }
      }
      ... on UploadAssetCommand {
        elementId
        ingestionState
        traits
      }
    }
  }
`;

export const CURSOR_QUERY = gql`
  query workspaceCursor($workspace: String!) {
    elementsWithMeta(
      workspaceId: $workspace
    ) {
      cursor
    }
  }
`;
